import React, { useState, useEffect } from "react";
import "./Quotes.css"; // Import the CSS file

const Quote = () => {
  const [quotes, setQuotes] = useState([]); // State to hold the quotes
  const [currentIndex, setCurrentIndex] = useState(0); // State to hold the current quote index
  const [loading, setLoading] = useState(true); // State to track loading status
  const [error, setError] = useState(null); // State to track errors
  const [showCongrats, setShowCongrats] = useState(false); // State to track if the last quote is reached

  // Fetch quotes from the API
  useEffect(() => {
    const fetchQuotes = async () => {
      try {
        const response = await fetch(
          "https://momentomori.onrender.com/v1/get_all_quotes/?shuffle=true"
        ); // Fetch quotes from your API endpoint
        const data = await response.json();

        if (data.status === "SUCCESS") {
          setQuotes(data.data); // Set quotes state with the fetched data
        } else {
          setError("Failed to fetch quotes");
        }
      } catch (err) {
        setError("An error occurred while fetching quotes");
        console.error("Error fetching quotes:", err);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchQuotes();
  }, []); // Empty dependency array means this runs once when the component mounts

  const nextQuote = () => {
    if (currentIndex === quotes.length - 1) {
      setShowCongrats(true); // Show congrats message if it's the last quote
    } else {
      setCurrentIndex((prev) => prev + 1); // Move to the next quote
    }
  };

  const previousQuote = () => {
    setShowCongrats(false); // Hide congrats message when going back
    setCurrentIndex((prev) => (prev - 1 + quotes.length) % quotes.length); // Move to the previous quote
  };

  if (loading) {
    return <div>Loading...</div>; // Show loading message while fetching
  }

  if (error) {
    return <div>{error}</div>; // Show error message if any
  }

  return (
    <div className="quote-container">
      <div className="quote-card">
        <button
          onClick={previousQuote}
          className="quote-nav-button prev-button"
          aria-label="Previous quote"
        >
          ←
        </button>

        <div className="quote-content">
          {showCongrats ? (
            <div className="congrats-message">
              <h1>🎉 Congratulations! 🎉</h1>
              <p>You've reached the end of the quotes!</p>
            </div>
          ) : (
            <>
              <div className="avatar">
                <img
                  src={quotes[currentIndex].avatar}
                  alt="Avatar"
                  className="avatar-img"
                />
              </div>

              <p className="quote-text">{quotes[currentIndex].text}</p>

              <p className="quote-author">
                — {quotes[currentIndex].author} in {quotes[currentIndex].source}
              </p>
            </>
          )}
        </div>

        <button
          onClick={nextQuote}
          className="quote-nav-button next-button"
          aria-label="Next quote"
        >
          →
        </button>
      </div>

      {/* Display current quote index and total number of quotes */}
      <div className="quote-index">
        <span>
          {currentIndex + 1} / {quotes.length}
        </span>
      </div>
    </div>
  );
};

export default Quote;
